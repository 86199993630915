<template>
  <div v-if="show" id="app">
    <router-view />
    <!-- 密码修改 -->
    <EditPassword />
    <!-- 账号状态弹窗 -->
    <AccountStatusDialog />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditPassword from "@/components/EditPassword";
import AccountStatusDialog from "@/components/AccountStatusDialog";
import { tokenLogin } from "@/api/base";
import getBrowserFinger from "device-key";

export default {
  components: {
    EditPassword,
    AccountStatusDialog
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters("companyInfo", ["noLoginLook", "companyInfo"])
  },
  watch: {
    noLoginLook() {
      if (!this.noLoginLook) {
        if (localStorage.isLogin !== "true") {
          this.$router.replace({
            path: "/login"
          });
        }
      }
    },
    companyInfo() {
      // 不允许非正式客户访问 && 未登录
      if (!this.companyInfo.setting.allowInformalCustomer && localStorage.isLogin !== "true") {
        // 跳转登录页面
        this.$router.replace({
          path: "/login"
        });
      }
    }
  },
  created() {
    if (window.token) {
      this.tokenLoginFn();
    } else {
      this.show = true;
    }
  },
  methods: {
    async tokenLoginFn() {
      let device = "";
      const res = await Promise.all([getBrowserFinger()]);
      if (res && res.length) {
        device = res[0];
      }
      const data = {
        token: window.token, // 登录token
        type: 1, // 设备平台类型 web:1/app:2/h5:3
        device: device, // 设备号
        system: "" // 系统
      };
      tokenLogin(data)
        .then(res => {
          window.token = "";
          if (res) {
            // 商家端登录
            window.location.href = `${window.location.origin}/seller`;
          } else {
            localStorage.isLogin = "true";
            window.location.replace("/");
          }
        })
        .catch(err => {
          this.show = true;
          window.token = "";
          this.$message.error(err.message || "登录失败！");
          this.$router.replace({
            path: "/login"
          });
        });
    }
  }
};
</script>

<style lang="less">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  width: 100%;
  height: 100%;
}
</style>
